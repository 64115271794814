import { defineStore } from "pinia";

export const useBonusPartnerWithManufacturerBuyoutStore =
  defineStore("bonusPartnerWithManufacturerBuyout", () => {
    const api = useApi();
    const { $api } = useNuxtApp() as { $api: IApiInstance };
    const { t } = useI18n();
    const { notify } = useNotification();
    const localePath = useLocalePath();
    const runtimeConfig = useRuntimeConfig();
    const buyoutStore = useBuyoutStore();
    const commonStore = useCommonStore();

    const newDeviceBonusStep = ref<number>(0);
    const selectedBonusPartner = ref<PartnerDetail | null>(null);
    const loadingBonusManufacturer = ref<boolean>(false);
    const loadingNewDevicesDeviceType = ref<boolean>(false);
    const availableNewDeviceTypes = ref<BuyoutDeviceType[]>([]);
    const selectedNewDeviceType = ref<BuyoutDeviceType | null>(null);
    const loadingNewDeviceMetaMasters = ref<boolean>(false);
    const availableNewDeviceMetaMasters = ref<BonusMetaMaster[]>([]);
    const selectedNewDeviceMetaMaster = ref<BonusMetaMaster | null>(null);
    const loadingAvailableNewDeviceProductVariants = ref<boolean>(false);
    const availableNewDeviceProductVariants = ref<BuyoutDeviceVariantForBonus | null>(null);
    const selectedNewDeviceProductColor = ref<BuyoutProductColor | null>(null);
    const selectedNewDeviceProductVariant = ref<BuyoutDeviceAttributeValueForBonus | null>(null);
    const newDeviceImei = ref<string | null>(null);
    const selectedNewDevicePartner = ref<NewDevicePartner | null>(null);
    const loadingAvailableNewDeviceProductColors = ref<boolean>(false);
    const availableNewDeviceProductColors = ref<BuyoutProductColor[]>([]);
    const availableNewDevicePartners = ref<NewDevicePartner[]>([]);
    const loadingNewDevicePartners = ref<boolean>(false);
    const moveToPersonalDataLoading = ref<boolean>(false);
    const newDeviceProcessAllowed = ref<boolean>(true);
    const newDeviceProcessErrorMessage = ref<string>("");
    const takeNewDeviceStep = ref<boolean>(true);
    const buyoutFinished = ref<boolean>(false);
    const buyoutFinishedByHandover = ref<boolean>(false);
    const buyoutContractResponseLoading = ref<boolean>(false);
    const availableNewDeviceTypesLoaded = ref<boolean>(false);
    const bonusPeriod = ref<BonusPeriod | null>(null);

    async function resetNewDeviceBuyoutProcess() {
      await resetNewDeviceMetaMasters();
      newDeviceBonusStep.value = 0;
      selectedNewDeviceType.value = null;
      availableNewDeviceTypes.value = [];
      availableNewDeviceMetaMasters.value = [];
      availableNewDeviceProductVariants.value = null;
      newDeviceImei.value = null;
      selectedNewDevicePartner.value = null;
      availableNewDeviceProductColors.value = [];
      availableNewDevicePartners.value = [];
      buyoutFinished.value = false;
      buyoutFinishedByHandover.value = false;
      availableNewDeviceTypesLoaded.value = false;
      bonusPeriod.value = null;
    }

    async function resetSelectedNewDeviceData(){
      selectedNewDeviceType.value = null;
      selectedNewDeviceMetaMaster.value = null;
      selectedNewDeviceProductColor.value = null;
      selectedNewDeviceProductVariant.value = null;
      newDeviceImei.value = null;
      selectedNewDevicePartner.value = null;
    }

    async function moveToPersonalData (): Promise<boolean> {
      moveToPersonalDataLoading.value = true;
      const { data, error } = await api.call<string>(api.commonUrls.buyout.CREATE_BONUS_NEW_DEVICE(), {
        method: "PUT",
        body: {
          new_device_id: selectedNewDeviceProductColor?.value?.id ? selectedNewDeviceProductColor!.value.id : null,
          bonuses_ids: buyoutStore.selectedBuyoutBonuses && buyoutStore.selectedBuyoutBonuses?.length > 0 ? buyoutStore.selectedBuyoutBonuses.map(bonus => bonus.id) : [],
          registration_number: buyoutStore.userBuyoutCode,
          is_new_device_process: true,
          customer_id: buyoutStore.getBuyoutCustomerId()
        }
      });
      if (data.value) {
        if(takeNewDeviceStep.value) buyoutStore.mainBuyoutStep += 1;
        moveToPersonalDataLoading.value = false;
        return true;
      }
      if (error.value) {
        notify({
          title: t("form.common.error"),
          text: t("general.error_sending_request"),
          type: "error"
        });
      }
      moveToPersonalDataLoading.value = false;
      return false;
    }

    async function loadAvailableNewDevicePartners(mainOnly: number = 0){
      if(!selectedBonusPartner.value?.id){
        return;
      }
      loadingNewDevicePartners.value = true;
      const { data, error } = await api.call<NewDevicePartner[]>(api.commonUrls.buyout.GET_NEW_DEVICES_PARTNERS(selectedBonusPartner.value!.id, mainOnly));
      if (data.value) {
        availableNewDevicePartners.value = data.value;
      }
      if (error.value) {
        notify({
          title: t("form.common.error"),
          text: t("general.error_sending_request"),
          type: "error"
        });
      }
      loadingNewDevicePartners.value = false;
    }

    async function loadBonusPartner (partnerSeoName: string) {
      loadingBonusManufacturer.value = true;
      const { data, error } = await api.call<PartnerDetail>(api.commonUrls.user.BONUS_PARTNER_WITH_MANUFACTURER(partnerSeoName));
      if (data.value) {
        selectedBonusPartner.value = data.value;
      }
      if (error.value) {
        if(error.value.statusCode === 404){
          newDeviceProcessAllowed.value = false;
        }
        else if (error.value.statusCode === 400) {
          newDeviceProcessAllowed.value = false;
          newDeviceProcessErrorMessage.value = error.value.data;
        }
        else {
          notify({
            title: t("form.common.error"),
            text: t("general.error_sending_request"),
            type: "error"
          });
        }
      }
      loadingBonusManufacturer.value = false;
    }

    async function loadBuyoutBonuses () {
      if (!buyoutStore.userBuyoutCode) {
        return;
      }
      buyoutStore.buyoutBonusesLoading = true;
      buyoutStore.selectedBuyoutBonuses = await commonStore.loadBuyoutBonuses(buyoutStore!.userBuyoutCode, selectedBonusPartner!.value!.id, true);
      buyoutStore.buyoutBonusesLoading = false;
    }


    async function loadAvailableDeviceTypesForNewProducts () {
      if(!selectedBonusPartner.value) {
        return;
      }
      loadingNewDevicesDeviceType.value = true;
      const { data, error } = await api.call<BuyoutDeviceType[]>(
        api.commonUrls.product.DEVICE_TYPES_FOR_BONUS_FOR_PARTNER(selectedBonusPartner.value.seo_name),
      );
      if (data.value) {
        availableNewDeviceTypes.value = data.value;
      }
      if (error.value) {
        notify({
          title: t("form.common.error"),
          text: t("general.error_sending_request"),
          type: "error"
        });
      }
      availableNewDeviceTypesLoaded.value = true;
      loadingNewDevicesDeviceType.value = false;
    }

    async function loadAvailableNewDeviceMetaMasters () {
      if (!selectedNewDeviceType.value) { return; }
      loadingNewDeviceMetaMasters.value = true;
      const { data, error } = await api.call<BonusMetaMaster[]>(api.commonUrls.product.META_MASTER_BY_CATEGORY_FOR_BONUS_FOR_PARTNER(selectedNewDeviceType.value!.identifier, selectedBonusPartner.value!.seo_name));
      if (data.value) {
        availableNewDeviceMetaMasters.value = data.value;
      }
      if (error.value) {
        notify({
          title: t("form.common.error"),
          text: t("general.error_sending_request"),
          type: "error"
        });
      }
      loadingNewDeviceMetaMasters.value = false;
    }

    async function loadAvailableNewDeviceProductVariants () {
      if (!selectedNewDeviceMetaMaster.value || !selectedBonusPartner.value?.id) { return; }
      loadingAvailableNewDeviceProductVariants.value = true;
      const { data, error } = await api.call<BuyoutDeviceVariantForBonus>(api.commonUrls.product.ATTR_CHOICES_FOR_NEW_BY_META_MASTER_NO_BUYOUT_DEVICE(selectedNewDeviceMetaMaster.value!.id, selectedBonusPartner.value!.id));
      if (data.value) {
        availableNewDeviceProductVariants.value = data.value;
      }
      if (error.value) {
        notify({
          title: t("form.common.error"),
          text: t("general.error_sending_request"),
          type: "error"
        });
      }
      loadingAvailableNewDeviceProductVariants.value = false;
    }

    async function loadBonusPeriod(){
        if (!selectedNewDeviceProductVariant.value || !selectedBonusPartner.value?.id) { return; }
        const { data, error } = await $api.buyout.getBonusPeriod(selectedNewDeviceProductVariant.value.master_product.id, selectedBonusPartner.value.id);
        bonusPeriod.value = data.value;
        if (error.value) {
          notify({
            title: t("form.common.error"),
            text: t("general.error_sending_request"),
            type: "error"
          });
        }
    }


    async function loadAvailableNewDeviceProductColors () {
      if (!selectedNewDeviceProductVariant.value || !selectedBonusPartner.value?.id) { return; }
      loadingAvailableNewDeviceProductColors.value = true;
      const { data, error } = await api.call<BuyoutProductColor[]>(api.commonUrls.product.COLOR_CHOICES_BY_MASTER_DEVICE_BY_PARTNER_NEW(selectedNewDeviceProductVariant.value.master_product.id, selectedBonusPartner.value.id));
      if (data.value) {
        availableNewDeviceProductColors.value = data.value;
      }
      if (error.value) {
        notify({
          title: t("form.common.error"),
          text: t("general.error_sending_request"),
          type: "error"
        });
      }
      loadingAvailableNewDeviceProductColors.value = false;
    }

    async function selectNewDeviceProductColor (productColor: BuyoutProductColor) {
      if (selectedNewDeviceProductColor.value !== productColor) {
        selectedNewDeviceProductColor.value = productColor;
        await changeURLNewDevice();
      }
    }

    async function selectNewDeviceProductVariant (productVariant: BuyoutDeviceAttributeValueForBonus) {
      if (selectedNewDeviceProductVariant?.value?.master_product.id !== productVariant.master_product.id) {
        selectedNewDeviceProductVariant.value = productVariant;
        await loadBonusPeriod();
        await resetNewDeviceProductColor();
        await loadAvailableNewDeviceProductColors();
        await changeURLNewDevice();
      }
    }


    async function resetNewDeviceMetaMasters (withDeps: boolean = true) {
      selectedNewDeviceMetaMaster.value = null;
      availableNewDeviceMetaMasters.value = [];
      if (withDeps) {
        await resetNewDeviceProductVariant(false);
        await resetNewDeviceProductColor();
      }
    }

    async function resetNewDeviceProductVariant (withDeps: boolean = true) {
      selectedNewDeviceProductVariant.value = null;
      availableNewDeviceProductVariants.value = null;
      if (withDeps) {
        await resetNewDeviceProductColor();
      }
    }

    async function resetNewDeviceProductColor () {
      selectedNewDeviceProductColor.value = null;
      availableNewDeviceProductColors.value = [];
    }

    async function changeURLNewDevice () {
      await nextTick();
      const params = {
        new_device_type: selectedNewDeviceType.value?.seo_name,
        new_meta_master: selectedNewDeviceMetaMaster.value?.seo_name,
        new_variant: selectedNewDeviceProductVariant.value?.master_product?.seo_name,
        new_color: selectedNewDeviceProductColor.value?.seo_name,
        type: buyoutStore.selectedDeviceType?.seo_name,
        manufacturer:  buyoutStore.selectedManufacturer?.seo_name,
        meta_master:  buyoutStore.selectedMetaMaster?.seo_name,
        variant:  buyoutStore.selectedProductVariant?.master_product?.seo_name,
        color:  buyoutStore.selectedProductColor?.seo_name,
        quality:  buyoutStore.selectedProductQuality?.quality.short_name

      };
      const filledUrlParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value));

      let url = localePath({ name: "bonus_partner-buyout-new_device_type-new_meta_master-new_variant-new_color-type-manufacturer-meta_master-variant-color-quality", params: filledUrlParams, query: { userBuyoutCode: buyoutStore.userBuyoutCode ? buyoutStore.userBuyoutCode : undefined }});

      if (buyoutStore.buyoutReferralId) {
        url += `?refId=${buyoutStore.buyoutReferralId}`;
      }

      window.history.replaceState({}, "", `/${runtimeConfig.public.region}${url}`);
    }

    async function printContactPdf () {
      if(!buyoutStore.userBuyoutCode){
        return;
      }
      buyoutContractResponseLoading.value = true;
      const { data, error } = await api.call(api.commonUrls.buyout.CONTRACT_BY_REGISTRATION_NUMBER(buyoutStore!.userBuyoutCode));
      if (error.value) {
        if(error.value.data){
          buyoutContractResponseLoading.value = false;
          return error.value.data;
        }
        else{
          notify({
            title: t("form.common.error"),
            text: t("general.error_sending_request"),
            type: "error"
          });
        }
      }

      if (data.value) {
        const blob = new Blob([data.value as BlobPart], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      }

      buyoutContractResponseLoading.value = false;
    }



    return {
      loadingNewDeviceMetaMasters,
      availableNewDeviceMetaMasters,
      selectedNewDeviceMetaMaster,
      newDeviceBonusStep,
      selectedBonusPartner,
      loadingNewDevicesDeviceType,
      availableNewDeviceTypes,
      selectedNewDeviceType,
      loadingAvailableNewDeviceProductVariants,
      availableNewDeviceProductVariants,
      selectedNewDeviceProductColor,
      selectedNewDeviceProductVariant,
      availableNewDeviceProductColors,
      loadingAvailableNewDeviceProductColors,
      availableNewDevicePartners,
      loadingNewDevicePartners,
      newDeviceImei,
      selectedNewDevicePartner,
      newDeviceProcessAllowed,
      newDeviceProcessErrorMessage,
      takeNewDeviceStep,
      buyoutFinished,
      buyoutFinishedByHandover,
      buyoutContractResponseLoading,
      availableNewDeviceTypesLoaded,
      bonusPeriod,
      loadAvailableNewDevicePartners,
      changeURLNewDevice,
      loadBonusPartner,
      loadAvailableDeviceTypesForNewProducts,
      loadAvailableNewDeviceMetaMasters,
      resetNewDeviceMetaMasters,
      resetNewDeviceProductVariant,
      loadAvailableNewDeviceProductVariants,
      selectNewDeviceProductColor,
      selectNewDeviceProductVariant,
      loadAvailableNewDeviceProductColors,
      loadBuyoutBonuses,
      moveToPersonalData,
      resetSelectedNewDeviceData,
      resetNewDeviceBuyoutProcess,
      printContactPdf,
      loadBonusPeriod
    };
  });
